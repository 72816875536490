@import 'main';

.transcoding-selectors {
  @include color-var(background-color, field-color);
  @include color-var(color, secondary-text-color);

  align-items: center;
  border-radius: $border-radius-size;
  box-sizing: border-box;
  display: flex;
  height: 46px;
  justify-content: space-between;
  margin-bottom: 2px;
  opacity: 1;
  padding: 0 10px 0 20px;
  transition: 0.2s height, 0.2s opacity, 0.2s padding, 0.2s margin-bottom, visibility 0.2s;
  visibility: visible;
  font-size: 13px;

  &--collapsed {
    height: 0;
    margin-bottom: 0;
    opacity: 0;
    padding: 0 20px;
    visibility: hidden;
  }

  &__container {
    display: flex;
  }

  &__label {
    align-items: center;
    display: flex;
    justify-content: center;

    &--short {
      display: none;
    }
  }

  @include respond-to(mobile) {
    border-radius: 0;

    &__label {
      &--short {
        display: block;
      }

      &--full-length {
        display: none;
      }
    }
  }

  @include respond-to(phone) {
    &__label {

      &--short,
      &--full-length {
        display: none;

        +.selector {
          margin-left: 0;
        }
      }
    }
  }

  @include respond-to(small-phone) {
    display: none;
  }
}
