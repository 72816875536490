@import 'main';

.tool {
  @include reset-button;
  @include color-var(color, secondary-text-color);

  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: visible;
  font-weight: bold;
  font-size: 13px;

  svg {
    max-width: 24px;
    max-height: 24px;
  }

  &:focus {
    svg path {
      @include color-var(fill, action-color);
    }
  }

  &--separated {
    @include color-var(border-right-color, background-color);

    border-right-width: 4px;
    border-right-style: solid;
    padding-right: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
}
